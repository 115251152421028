<template>
    <div id="sidebar">
        <div class="sidebar-inner">

            <div id="sidebar-etc">

                <b-row >
                    <b-col sm="12">
                        <router-link class="nounderline" :to="{ name: 'property', params: { propertyId: bookingData.hotelId, propertyName: this.bookingData.hotelName }}">
                            <BtnBack :text="'Torna indietro'" />
                        </router-link>
                        <br>
                    </b-col>
                </b-row>

                <div>
                    <div class="sidebar-checkout-panel">

                        <!-- credito welfare/info utente -->
                        <div v-if="!this.mixinIsPublic()" class="panel-content top">
                            <img :src="avatarSrc" />
                            <div>
                                <p class="name">{{displayName}}</p>
                                <!--<p v-if="availability > 0" class="credit">Credito Welfare € {{availability}}</p>-->
                            </div>
                        </div>

                        <!-- Riepilogo dei costi -->
                        <div class="panel-content">
                            <h5 class="panel-title">Riepilogo dei costi</h5>
                            <div class="checkout-resume-block pb-0">
                                <div class="checkout-resume-row align-left mb-2">
                                  <b>{{bookingData.roomDescription}}</b>
                                </div>
                                <div class="checkout-resume-row align-left pl-3 mb-2">
                                  <div>
                                    {{bookingData.numberOfAdults}} <span v-if="bookingData.numberOfAdults > 1">adulti</span><span v-else>adulto</span>
                                    <span v-if="bookingData.childs.length">, {{bookingData.childs.length}} <span v-if="bookingData.childs.length > 1">bambini</span><span v-else>bambino</span>
                                    (<span v-for="(c,i) in bookingData.childs" v-bind:key="_uid+i">{{ c }}<span v-if="i<(bookingData.childs.length-1)">, </span></span> anni)</span>
                                  </div>
                                </div>
                                <div class="checkout-resume-row pl-3">
                                    <span>{{bookingData.nights}} <span v-if="bookingData.nights > 1">Notti</span><span v-else>Notte</span></span>
                                    <span>
                                        {{mixinRoundTwoDigits(bookingData.totals.exclusive)}} €
                                    </span>
                                </div>
                                <div class="checkout-resume-row pl-3">
                                    <span
                                        v-b-popover.hover.top="'Questo addebito include gli importi stimati che il fornitore dei servizi di viaggio (ad esempio hotel, società di autonoleggio) paga in forma di tasse e/o le tasse che paghiamo alle autorità fiscali sulla tua prenotazione (incluse, a titolo esemplificativo, le imposte sulle vendite, di soggiorno o sul valore aggiunto). Può anche includere eventuali importi a noi addebitati per le tariffe resort, i supplementi per le pulizie e altre spese e/o un costo che noi, il fornitore dell\'hotel e/o il sito su cui hai prenotato tratteniamo come parte del compenso per i nostri servizi, che varia in base a fattori quali la posizione, l\'importo e la modalità di prenotazione. Per maggiori informazioni, consulta i termini e le condizioni.'"
                                        id="info-taxes" class="cursor-pointer">Tasse ed oneri <SvgInfoCircle />
                                    </span>
                                    <span>
                                        {{mixinRoundTwoDigits(bookingData.totals.inclusive - bookingData.totals.exclusive)*100/100}} €
                                    </span>
                                </div>
                                <div class="checkout-resume-row pl-3">
                                    <span v-b-popover.hover.top="'Costi di gestione della tua prenotazione e per l\'assistenza multicanale e dedicata ' + site.brand + '.'" id="info-taxes" class="cursor-pointer">{{ site.brand }} service charge <SvgInfoCircle />
                                    </span>
                                    <span>
                                        {{mixinRoundTwoDigits(bookingData.live_price - bookingData.totals.inclusive)*100/100}} €
                                    </span>
                                </div>
                            </div>

                            <div class="checkout-resume-block pb-0">
                                <div class="checkout-resume-row">
                                    <strong>Totale per il viaggio</strong>
                                    <strong>{{mixinRoundTwoDigits(bookingData.live_price)}} €</strong>
                                </div>

                                Totalmente saldato al momento della prenotazione
                                <ScalapayWidget v-if="useScalapay && scalapayable" :amount="mixinRoundTwoDigits(bookingData.live_price)"/>

                                <div v-else>
                                  <br/>
                                  <img src="assets/pagamenti/scalapay.svg" style="max-width:100px;display:inline"/>
                                  &nbsp;non è disponibile perchè hai superato l'ammontare massimo finanziabile di € {{scalapay.maxAmount}}
                                </div>

                                <br/>

                            </div>

                            <div class="checkout-resume-block pb-0" v-if="this.$route.name == 'checkout' && discountCodeResidualAsAvailability">

                              <strong>Crediti disponibili</strong>
                              <div class="d-flex justify-content-center">
                                <small>Potranno essere utilizzati nella fase di pagamento</small>
                              </div>

                              <div class="checkout-resume-row" v-for="(code,index) in codes" v-bind:key="'dc2_'+index">
                                <span>Buono sconto {{code.discountCode}}</span>
                                <span>{{mixinRoundTwoDigits(code.valueResidual)}} €</span>
                              </div>

                            </div>

                            <div class="checkout-resume-block sidebarResumeBlock pb-0" v-if="this.$route.name == 'checkout' && this.codes && this.codes.length == 0">
                                <strong>Potrai pagare con...</strong>
                                <div class="checkout-resume-row mb-0 pb-0">
                                    <div class="payment-logos">
                                        <img class="mastercard" src="assets/mastercard.svg" />
                                        <img class="visa" src="assets/visa.svg" />
                                        <img class="american-express" src="assets/american-express.svg" />
                                        <img class="buono-sconto-vivamod" src="assets/buono-sconto-vivamod.svg" />
                                        <img v-if="useEtc" class="edenred-logo" src="assets/edenred-logo.svg" />
                                        <img v-if="usePromoshopping" class="promoshopping" src="assets/promoshopping.svg" />
                                        <img v-if="useScalapay && scalapayable" class="scalapay" src="assets/pagamenti/scalapay-payoff.svg" />
                                        <img v-if="useSatispay" class="satispay" src="assets/pagamenti/satispay.png" />
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <small>Potranno essere utilizzati nella fase di pagamento</small>
                                </div>
                            </div>

                            <div class="checkout-resume-block pb-0" v-if="this.$route.name == 'checkout' && fees != 0">
                                <strong>Costi obbligatori non inclusi</strong>
                                <div class="checkout-resume-row" v-if="bookingData.totals.mandatory_tax !== 0">
                                    <span v-b-popover.hover.top="'Il costo della tassa di soggiorno viene definito dall\'Amministrazione Comunale, deve essere pagato in struttura ed è soggetto a variazioni. Il costo che vedi sul nostro sito è stato inserito dalla struttura alberghiera e non possiamo garantire che sia stato inserito correttamente e che sia aggiornato. Se desideri informazioni definitive ti invitiamo a consultare il sito dell\'Amministrazione Comunale o a contattare la struttura alberghiera.'">
                                      Tassa di soggiorno
                                      <SvgInfoCircle />
                                    </span>
                                    <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_tax)}} €</span>
                                </div>
                                <div class="checkout-resume-row" v-if="bookingData.totals.mandatory_fee !== 0">
                                    <span>Spese di pulizia ed utenze</span>
                                    <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_fee)}} €</span>
                                </div>
                                <div class="checkout-resume-row" v-if="bookingData.totals.resort_fee !== 0">
                                    <span>Resort fee</span>
                                    <span>{{mixinRoundTwoDigits(bookingData.totals.resort_fee)}} €</span>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <small>Da pagare in struttura al check-in</small>
                                </div>
                            </div>

                            <div class="sidebarResumeBlock pb-0" v-else-if="this.$route.name == 'checkout'">

                              <strong>Costi obbligatori non inclusi</strong>
                              <div class="d-flex justify-content-center">
                                La struttura non ha comunicato altri costi obbligatori da pagare in loco. Controlla anche il capitolo Informazioni importanti che trovi in questa pagina oppure contattaci per maggiori informazioni.
                              </div>

                            </div>

                        </div>

                        <!-- dati del viaggiatore -->
                        <div class="panel-content">
                            <h5 class="panel-title" v-b-toggle.checkoutStatusUserData>
                                <span class="checkoutDynamicBlockTitle">
                                    <span class="stepNumber">1</span> Dati del viaggiatore
                                </span>
                                <SvgChevronDown class="chevron" />
                            </h5>
                            <b-collapse id="checkoutStatusUserData" v-model="checkoutStatusUserDataVisible" accordion="checkoutStatus">
                                <div class="checkout-resume-block">

                                    <!-- SIDEBAR REFACTOR: sub component della sidebar, in futuro saranno tutti così; potenzialmente anche il tasto Conferma di ciascun cassetto potrebbe essere gestito a livello di sub component -->
                                    <CheckoutPax
                                        inputSize="lg"
                                        v-if="sidebarBlocks.checkoutPax.initialize"
                                        v-on:sidebarBlockChanged="sidebarBlockChange"
                                    />

                                    <!-- SIDEBAR REFACTOR: sub component della sidebar, in futuro saranno tutti così; potenzialmente anche il tasto Conferma di ciascun cassetto potrebbe essere gestito a livello di sub component -->
                                    <CheckoutBillToPax
                                        inputSize="lg"
                                        v-if="sidebarBlocks.checkoutBillToPax.initialize"
                                        v-on:sidebarBlockChanged="sidebarBlockChange"
                                    />
                                    
                                    <div class="checkoutStatusButtons mt-4">
                                        <b-button :disabled="!getUserDataStatus()" variant="success" v-b-toggle.checkoutStatusPaymentMethods>Conferma</b-button>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>

                        <!-- Strumenti di pagamento -->
                        <div class="panel-content checkoutCodes">
                            <h5 class="panel-title" v-if="getUserDataStatus()" v-b-toggle.checkoutStatusPaymentMethods>
                                <span class="checkoutDynamicBlockTitle">
                                    <span class="stepNumber">2</span>
                                    Pagamento
                                </span>
                                <SvgChevronDown class="chevron" />
                            </h5>
                            <h5 class="panel-title" v-else>
                                <span class="checkoutDynamicBlockTitle">
                                    <span class="stepNumber">2</span>
                                    Pagamento
                                </span>
                            </h5>
                            <b-collapse id="checkoutStatusPaymentMethods" v-model="checkoutStatusPaymentMethodsVisible" accordion="checkoutStatus">
                                <div v-if="!loadingDc" class="checkout-resume-block">
                                    <div role="tablist">

                                        <!-- carta di credito -->
                                        <div class="paymentPartnerBlock credit-card" v-b-toggle.checkoutStatusConfirm>
                                            <div class="icons">
                                                <img src="/assets/pagamenti/visa.svg">
                                                <img src="/assets/pagamenti/mastercard.svg">                                                
                                                <img src="/assets/pagamenti/american-express.svg">
                                            </div>
                                        </div>

                                        <!-- Scalapay -->
                                        <div class="paymentPartnerBlock" v-if="useScalapay && mixinIsPublic() && (codes && codes.length == 0)" @click="scalapayStoreAndChangeSidebar()">
                                          <div class="icon">
                                            <img class="logo" src="assets/pagamenti/scalapay.svg" />
                                          </div>
                                          <div v-if="useScalapay && scalapayable" class="name etcBlue">
                                            {{ scalapay.numberOfPayments }} rate da {{mixinRoundTwoDigits(bookingData.live_price/scalapay.numberOfPayments)}} € senza interessi
                                          </div>
                                          <div v-else class="name etcBlue">
                                            non è disponibile per questa prenotazione
                                          </div>
                                        </div>

                                        <!-- Satispay -->
                                        <div class="paymentPartnerBlock" v-if="useSatispay && mixinIsPublic() && (codes && codes.length == 0)" @click="satispayStoreAndChangeSidebar()">
                                          <div class="icon">
                                            <img class="logo" src="assets/pagamenti/satispay.png" />
                                          </div>
                                          <div class="name etcBlue">
                                            Paga con Satispay
                                          </div>
                                        </div>
                                        
                                        <!-- Ticket compliments -->
                                        <div class="paymentPartnerBlock" v-if="useEtc && mixinIsPublic() && (codes && codes.length == 0)" @click="etcStoreAndSso()">
                                            <div class="icon">
                                                <img class="logo" src="assets/edenred-logo-red-small.svg" />
                                            </div>
                                            <div class="name etcBlue">
                                                Usa i tuoi Ticket Compliments &reg;
                                            </div>
                                        </div>

                                        <!-- Blocco DC -->
                                        <b-form-group>

                                            <!-- nessun codice ancora caricato: form inserimento codice promoshopping -->
                                            <div v-if="usePromoshopping && (codes && codes.length == 0)" class="checkout-resume-row d-block paymentPartnerBlock">
                                              <div class="dc-logo">
                                                    <img class="logo" src="assets/promoshopping.svg">
                                                    <div class="newDiscountCodeBox">
                                                        <b-input
                                                            class="newDiscountCode invalid"
                                                            type="text"
                                                            ref="promoshoppingCode"
                                                            v-model="promoshoppingCode"
                                                            placeholder="es: RFdBkpTTHam8w76"
                                                            @input="validateCode(promoshoppingCode,('promoshoppingCode'))"
                                                        />
                                                        <b-button class="addButton">
                                                            <svg class="ok" @click="addCode(promoshoppingCode)" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="7.5" cy="7.5" r="7.5" fill="#0071BC"/>
                                                                <path d="M10.2729 8.4H8.14894V10.5H6.86494V8.4H4.74094V7.2H6.86494V5.1H8.14894V7.2H10.2729V8.4Z" fill="#E9EBF0"/>
                                                            </svg>                                                            
                                                            <svg class="ko" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="7.5" cy="7.5" r="7.5" fill="#999999"/>
                                                                <path d="M10.2729 8.4H8.14894V10.5H6.86494V8.4H4.74094V7.2H6.86494V5.1H8.14894V7.2H10.2729V8.4Z" fill="#E9EBF0"/>
                                                            </svg>                                                            
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- nessun codice ancora caricato: form inserimento codice con logo brandizzato -->
                                            <div v-if="codes && codes.length == 0" class="checkout-resume-row d-block paymentPartnerBlock">
                                                <div class="dc-logo">
                                                    <SvgLogoDiscountCode class="logo" />
                                                    <div class="newDiscountCodeBox">
                                                        <b-input
                                                            class="newDiscountCode invalid"
                                                            type="text"
                                                            ref="newDiscountCode"
                                                            v-model="newDiscountCode"
                                                            placeholder="es: RFdBkpTTHam8w76"
                                                            @input="validateCode(newDiscountCode,('newDiscountCode'))"
                                                            />
                                                        <b-button class="addButton">
                                                            <svg class="ok" @click="addCode(newDiscountCode)" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="7.5" cy="7.5" r="7.5" fill="#0071BC"/>
                                                                <path d="M10.2729 8.4H8.14894V10.5H6.86494V8.4H4.74094V7.2H6.86494V5.1H8.14894V7.2H10.2729V8.4Z" fill="#E9EBF0"/>
                                                            </svg>                                                            
                                                            <svg class="ko" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="7.5" cy="7.5" r="7.5" fill="#999999"/>
                                                                <path d="M10.2729 8.4H8.14894V10.5H6.86494V8.4H4.74094V7.2H6.86494V5.1H8.14894V7.2H10.2729V8.4Z" fill="#E9EBF0"/>
                                                            </svg>                                                            
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- codice già caricato: riepilogo -->
                                            <div v-if="codes && codes.length > 0">
                                                <div class="checkout-resume-row mt-4">
                                                    <div class="d-flex">
                                                        <span class="icon"><SvgTicketIcon /></span>
                                                        <strong>Buoni caricati</strong>
                                                    </div>
                                                </div>
                                                <div v-for="(code,index) in codes" v-bind:key="'dc_'+index" :class="'checkout-resume-row discountCodes mb-0'">
                                                    <div :class="
                                                            'discountCode '
                                                            + (code.usable ? code.valueUsed > 0? 'validCode':'warningCode' : 'invalidCode')
                                                            + ' '
                                                            + (code.usable && !code.divisible && (code.valueUsed == 0) ? 'warningCode ':'')
                                                            + (code.valueUsed == code.valueResidual ? 'fullDot ' : '')
                                                            + (code.valueUsed > 0 & (code.valueUsed < code.valueResidual) ? 'halfDot ' : '')
                                                            + (code.valueUsed == 0 ? 'grayDot ' : '')
                                                            + (!code.usable ? 'redDot ' : '')
                                                        ">
                                                        
                                                        <div v-b-toggle="'accordion-discountCode-' + index">
                                                            <div class="discountCodeTitle" v-if="!code.divisible && code.status == 'VALID'" >
                                                                <div class="d-flex">
                                                                    <!--<span class="icon"><SvgTicketIcon /></span>-->
                                                                    <span>
                                                                        <!--Buono sconto-->
                                                                        <span v-b-popover.hover.top="'Questo Buono può essere speso solo in modo completo e per una singola prenotazione'">
                                                                            NON frazionabile <SvgInfoCircle class="svgInfo" />
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <SvgChevronDown class="chevron" />
                                                            </div>
                                                            <div class="discountCodeRow">
                                                                <div class="copy copyDots">
                                                                    <div class="dcCode">{{code.discountCode}}</div>
                                                                </div>
                                                                <div v-if="code.status != 'EXPIRED'" class="spacer"></div>
                                                                <div v-if="code.status != 'EXPIRED'" class="value"><b>{{mixinRoundTwoDigits(code.valueUsed)}}€</b></div>
                                                            </div>
                                                        </div>

                                                        <b-collapse :id="'accordion-discountCode-' + index" accordion="accordion-discountCodes">

                                                            <!-- gestione errori / informazioni -->
                                                            <div class="discountCodeRow danger" v-if="code.valueUsed == 0">
                                                                <div class="mt-1 mb-1 copy d-flex justify-content-center align-items-center">
                                                                    <span class="mr-1">
                                                                        <font-awesome-icon icon="exclamation-triangle"/>
                                                                    </span>
                                                                    <span v-if="code.status == 'NOT FOUND'">
                                                                        Non abbiamo trovato questo buono sconto. Assicurati di averlo digitato correttamente rispettando MAIUSCOLE  e minuscole
                                                                    </span>
                                                                    <span v-else-if="code.status == 'EXPIRED'">
                                                                        Buono sconto scaduto il {{code.expirationDate}}
                                                                    </span>
                                                                    <span v-else-if="code.status == 'EMPTY'">
                                                                        Questo buono sconto è già stato interamente utilizzato per un'altra prenotazione
                                                                    </span>
                                                                    <span v-else-if="code.status == 'DISABLED'">
                                                                        Questo buono sconto non è attivo, contatta l'assistenza
                                                                    </span>
                                                                    <span v-else-if="!code.divisible && (code.valueUsed == 0)">
                                                                        Questo buono è di tipo non frazionabile e non può essere speso parzialmente
                                                                    </span>
                                                                    <span v-else-if="code.status == 'NOT CUMULABLE'">
                                                                        Questo buono sconto non è cumulabile, togli gli altri codici dalla lista per utilizzare questo
                                                                    </span>
                                                                    <!--<span v-else>
                                                                        Impossibile usare questo buono sconto
                                                                    </span>-->
                                                                </div>
                                                            </div>

                                                            <div class="discountCodeRow" v-if="code.cumulable == false">
                                                              <div class="copy" v-b-popover.hover.top="'Questo buono non può essere utilizzato congiuntamente ad altri buoni'">
                                                                <SvgInfoCircle class="svgInfo" /> <b>Non cumulabile</b>
                                                              </div>
                                                            </div>

                                                            <div class="discountCodeRow">
                                                                <div class="copy" v-b-popover.hover.top="'Valore del Buon all\'emissione'">
                                                                <SvgInfoCircle class="svgInfo" /> Valore originario
                                                                </div>
                                                                <div class="spacer"></div>
                                                                <div class="value">{{mixinRoundTwoDigits(code.valueNominal)}}€</div>
                                                            </div>

                                                            <div v-if="code.status == 'VALID'" class="discountCodeRow">
                                                              <div class="copy" v-b-popover.hover.top="'In base a possibili utilizzi del Buono sconto per precedenti prenotazioni'">
                                                                <SvgInfoCircle class="svgInfo" /> Valore disponibile
                                                              </div>
                                                              <div class="spacer"></div>
                                                              <div class="value">{{mixinRoundTwoDigits(code.valueResidual)}}€</div>
                                                            </div>

                                                            <!--
                                                            <div v-if="code.status == 'VALID'" class="discountCodeRow">
                                                                <div class="copy">
                                                                    Già utilizzato
                                                                </div>
                                                                <div class="spacer"></div>
                                                                <div class="value">{{(code.valueNominal - code.valueResidual)*100/100}}€</div>
                                                            </div>
                                                            -->

                                                            <div v-if="code.status == 'VALID'" class="discountCodeRow">
                                                                <div class="copy copyDots" v-b-popover.hover.top="
                                                                    'Il valore del Buono sconto '
                                                                    + (code.valueUsed == code.valueResidual ? 'viene utilizzato in toto per questa prenotazione' : '')
                                                                    + (code.valueUsed > 0 & (code.valueUsed < code.valueResidual) ? 'viene utilizzato solo parzialmente per questa prenotazione ' : '')
                                                                    + (code.valueUsed == 0 ? 'non verrà utilizzato per questa prenotazione. Resterà valido per altre prenotazioni' : '')
                                                                ">
                                                                    <SvgInfoCircle class="svgInfo" /> <b>Utilizzo per questa prenotazione</b>
                                                                </div>
                                                                <div class="spacer"></div>
                                                                <div class="value"><b>{{mixinRoundTwoDigits(code.valueUsed)}}€</b></div>
                                                            </div>

                                                            <div v-if="code.status == 'VALID'" class="discountCodeRow">
                                                                <div class="copy" v-b-popover.hover.top="'Ti invieremo una email con un riepilogo per un futuro utilizzo del residuo'">
                                                                    <SvgInfoCircle class="svgInfo" /> Valore disponibile dopo la prenotazione
                                                                </div>
                                                                <div class="spacer"></div>
                                                                <div class="value">{{mixinRoundTwoDigits(code.valueResidual - code.valueUsed)}}€</div>
                                                            </div>

                                                            <div class="discountCodeRemove" @click="removeCode(code.discountCode)">
                                                            <span v-if="code.status=='VALID'">Non utilizzare</span>
                                                            <span v-else>Togli dalla lista</span>
                                                            <span><font-awesome-icon icon="trash"/></span>
                                                            </div>

                                                        </b-collapse>

                                                    </div>
                                                </div>
                                            </div>

                                            <!-- codice già caricato: form inserimento generico -->
                                            <div v-if="codes && codes.length > 0 && !thereIsNonCumulableDiscountCode" class="checkout-resume-row d-block paymentPartnerBlock">
                                              <div class="dc-logo">
                                                <strong class="logo">Aggiungi</strong>
                                                <div class="newDiscountCodeBox">
                                                  <b-input
                                                      class="newDiscountCode invalid"
                                                      type="text"
                                                      ref="genericDiscountCode"
                                                      v-model="genericDiscountCode"
                                                      placeholder="es: RFdBkpTTHam8w76"
                                                      @input="validateCode(genericDiscountCode,('genericDiscountCode'))"
                                                  />
                                                  <b-button class="addButton">
                                                    <svg class="ok" @click="addCode(genericDiscountCode)" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <circle cx="7.5" cy="7.5" r="7.5" fill="#0071BC"/>
                                                      <path d="M10.2729 8.4H8.14894V10.5H6.86494V8.4H4.74094V7.2H6.86494V5.1H8.14894V7.2H10.2729V8.4Z" fill="#E9EBF0"/>
                                                    </svg>
                                                    <svg class="ko" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <circle cx="7.5" cy="7.5" r="7.5" fill="#999999"/>
                                                      <path d="M10.2729 8.4H8.14894V10.5H6.86494V8.4H4.74094V7.2H6.86494V5.1H8.14894V7.2H10.2729V8.4Z" fill="#E9EBF0"/>
                                                    </svg>
                                                  </b-button>
                                                </div>
                                              </div>
                                            </div>


                                        </b-form-group>

                                        <span class="togglePayment black">
                                            <span class="d-flex align-items-center">
                                                <font-awesome-icon icon="money-bill-alt"/> Riepilogo pagamenti
                                            </span>
                                        </span>

                                        <div class="checkout-resume-row discountCodes">
                                            <div class="dcResume">
                                                <div v-if="availabilityToUse > 0" class="discountCodeRow">
                                                  <div class="copy">
                                                    <font-awesome-icon icon="money-bill-alt"/> Credito
                                                  </div>
                                                  <div class="spacer"></div>
                                                  <div class="value">{{availabilityToUse}} €</div>
                                                </div>
                                                <div v-if="mixinRoundTwoDigits(number(discountCodesAmount)) > 0" class="discountCodeRow">
                                                    <div class="copy">
                                                        <!--<SvgTicketIcon /> -->Buoni caricati
                                                    </div>
                                                    <div class="spacer"></div>
                                                    <strong class="value">{{mixinRoundTwoDigits(number(discountCodesAmount))}} €</strong>
                                                </div>
                                                <div v-if="mixinRoundTwoDigits(creditCardAmount) > 0" class="discountCodeRow mb-1">
                                                    <div class="copy">
                                                        <!--<SvgCreditCard /> -->Carta di credito
                                                    </div>
                                                    <div class="spacer"></div>
                                                    <strong class="value">{{mixinRoundTwoDigits(creditCardAmount)}} €</strong>
                                                </div>
                                                <div v-if="mixinRoundTwoDigits(creditCardAmount) > 0" class="discountCodeRow">
                                                    <div class="copy">
                                                        <small>Clicca su Procedi per effettuare il pagamento con carta</small>
                                                    </div>
                                                </div>
                                                <!--
                                                <div class="discountCodeRow">
                                                    <div class="copy"></div>
                                                    <div class="spacer"></div>
                                                </div>
                                                -->
                                                <div class="discountCodeRow">
                                                    <strong class="copy">
                                                        Totale
                                                    </strong>
                                                    <div class="spacer"></div>
                                                    <strong class="value">{{mixinRoundTwoDigits(creditCardAmount)}} €</strong>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="checkoutStatusButtons mt-4">
                                        <b-button variant="success" v-b-toggle.checkoutStatusConfirm>Procedi</b-button>
                                    </div>
                                </div>
                                <Spinner v-else />
                            </b-collapse>
                        </div>


                        

                        <!-- Conferma -->
                        <div class="panel-content">
                            <h5 class="panel-title" v-if="getPaymentMethodsStatus()" v-b-toggle.checkoutStatusConfirm>
                                <span class="checkoutDynamicBlockTitle">
                                    <span class="stepNumber">3</span>
                                    Conferma
                                </span>
                                <SvgChevronDown class="chevron" />
                            </h5>
                            <h5 class="panel-title" v-else>
                                <span class="checkoutDynamicBlockTitle">
                                    <span class="stepNumber">3</span>
                                    Conferma
                                </span>    
                            </h5>
                            <b-collapse id="checkoutStatusConfirm" v-model="checkoutStatusConfirmVisible" accordion="checkoutStatus">
                                <div class="checkout-resume-block">

                                    <div v-if="mixinRoundTwoDigits(smaller(sum(number(discountCodesAmount), number(availabilityToUse)),  number(checkoutAmount)))">
                                        <div class="checkout-resume-row">
                                            <span>Paga con carta di credito</span><strong>{{string(round(number(creditCardAmount), 2))}} €</strong>
                                        </div>
                                        <div class="checkout-resume-row mb-0">
                                            <Stripe ref="stripe" :amount="number(creditCardAmount)" v-on:stripe-change="stripeChange" v-on:stripe-confirm="stripeConfirm" />
                                        </div>
                                    </div>

                                    <div class="checkout-resume-row mb-0">
                                        <b-form-group class="mb-0" id="checkout-form">
                                            <div class="privacy-box">
                                                <b-form-checkbox v-model="guestPrivacy" ref="checkoutPrivacy" required type="checkbox" name="checkbox" id="checkbox-privacy" />
                                                <label for="checkbox-privacy">
                                                    <router-link :to="{name: 'terms'}" target="_blank" class="nounderline">
                                                        <span>Ho preso visione delle condizioni d'uso e le accetto</span>
                                                    </router-link>
                                                </label>
                                            </div>

                                            <div class="privacy-box">
                                                <b-form-checkbox v-model="guestGdpr" ref="checkoutGdpr" required type="checkbox" name="checkbox" id="checkbox-gdpr" />
                                                <label for="checkbox-gdpr">
                                                    <p>
                                                        <a :href="site.urlPrivacy" target="_blank">Ho preso visione delle condizioni di trattamento dei dati e le accetto</a>
                                                    </p>
                                                </label>
                                            </div>

                                            <div class="privacy-box" v-if="disclaimerCheckout !== undefined">
                                                <label for="checkbox-gdpr">
                                                    <!--<p class="checkboxInfo" v-html="disclaimerCheckout"></p>-->
                                                    <p v-html="disclaimerCheckout"></p>
                                                </label>
                                            </div>

                                            <div v-if="!loading">
                                                <b-button v-if="creditCardAmount <= 0" class="btn btn-block btn-success" :disabled="!canFinallyCheckout()" @click="checkoutConfirm">PRENOTA!</b-button>
                                                <b-button v-else class="btn btn-block btn-success" :disabled="!canFinallyCheckout()" @click="getIntentClientSecret">PRENOTA!</b-button>
                                            </div>
                                            <Spinner v-bind:msg="'Prenotazione in corso... non abbandonare o ricaricare la pagina!'" v-else />
                                        </b-form-group>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>

                    </div>
                    

                    <!--<Stripe v-on:stripe-ok="stripeOk" />-->

                    <!-- confirm only -->
                    <div v-if="this.$route.name == 'confirm'">
                        <a href="/" class="btn btn-block btn-secondary">Torna alla Home</a>
                        <!--
                        TODO rendere contestuale
                        <br>
                        <a href="#" class="btn btn-block btn-secondary">Torna a Easy Welfare</a>-->
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Api from '../../../api'
import CheckoutBillToPax from './checkout-bill-to-pax' /* SIDEBAR REFACTOR */
import CheckoutPax from './checkout-pax' /* SIDEBAR REFACTOR */
import Stripe from '../stripe'
import SvgTicketIcon from './../../svg/ticket-icon'
import SvgInfoCircle from './../../svg/info-circle'
//import SvgCreditCard from './../../svg/credit-card'
import SvgChevronDown from './../../svg/chevron-down'
//import SvgEdenredLogoRedSmall from '../../svg/edenred-logo-red-small'
//import SvgCross from './../../svg/cross'
//import SvgCheck from './../../svg/check'
import Spinner from './../../atoms/spinner'
import BtnBack from './../../atoms/btn-back'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTrash, faExclamationTriangle, faMoneyBillAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
library.add(faMoneyBillAlt)
library.add(faTrash)
library.add(faExclamationTriangle)
library.add(faPlus)
Vue.component('font-awesome-icon', FontAwesomeIcon)

//import ResumePaymentEtc from './resume-payment-etc'
import ScalapayWidget from '@/components/blocks/scalapay-widget'
import {number, smaller, smallerEq, sum, subtract, string, round, larger} from "mathjs";


export default {
    name: 'sidebar-dc',
    components:
    {
        Spinner,
        SvgTicketIcon,
        SvgInfoCircle,
        //SvgCreditCard,
        SvgChevronDown, //SvgEdenredLogoRedSmall, //SvgCross, SvgCheck,
        BtnBack,
        Stripe,
        CheckoutPax, /* SIDEBAR REFACTOR */
        CheckoutBillToPax, /* SIDEBAR REFACTOR */
        //ResumePaymentEtc,
        SvgLogoDiscountCode: () => import('@/components/' + process.env.VUE_APP_CUSTOM_SRC + 'svg/logoDiscountCode')
          .then(function(SvgLogoDiscountCode) {
            window.console.log('async: SvgLogoDiscountCode Component loaded')
            return SvgLogoDiscountCode
          }),
        ScalapayWidget,
    },
    props:
    {
        //bookingData: Object
    },
    computed: {
        availability: {
            get() {
              // du sidebar-dc availability viene usata per fare i conti
              // devo gestire il caso "-1" (che a fini di conteggio prezzo và ignorato)
              if(this.$store.state.integration && this.$store.state.integration.availability && this.$store.state.integration.availability > 0){
                return this.$store.state.integration.availability
              }else{
                return 0
              }

            },
            set(value) { this.mixinSendMutation('setAvailability',value) }
        },
        token: {
            get() { return this.$store.state.token },
            set(value) { this.mixinSendMutation('setToken',value) }
        },
        bookingData: {
            get() { return this.$store.state.bookingData },
            set(value) { this.mixinSendMutation('setBookingData',value) }
        },
        affiliateReferenceId: {
            get() { return this.$store.state.affiliateReferenceId },
            set(value) { this.mixinSendMutation('setAffiliateReferenceId',value) }
        },
        discountCodes: {
            get() { return this.$store.state.discountCodes },
            set(value) { this.mixinSendMutation('setDiscountCodes',value) }
        },
        availabilityToUse: {
          get() {

            // questa computed ritorna quanto credito "availability" è possibile utilizzare per la prentazione
            // (quindi la differenza andrà "addebitata" su cc e/o codici sconto)


            let availability = this.availability;


            if(Vue.prototype.$config.guiSettings.discountCodeResidualAsAvailability){

              /*
              *   su be c'è un'opzione per gestire la disponibilità dei codici sconto come availability
              *   (lbDiscountCodeResidualAsAvailability)
              *
              *   per far quadrare tutti i conti bisogna sottrarre l'ammontare spendibile dei codici sconto
              *   alla disponibilità "gonfiata" dal valore dei codici sconto
              *
              */

              let totDiscountCodesResidual = 0;
              this.codes.forEach(code => {
                totDiscountCodesResidual += parseFloat(code.valueResidual)
              })
              availability -= totDiscountCodesResidual;

            }

            // per il pagamento posso usare tutta la disponibilità o, se maggiore, l'ammontare necessario per la prenotazione
            return (number(availability) <= number(this.checkoutAmount))? availability : this.checkoutAmount

          },
          set() { }
        },
        scalapayable: {
          get() {

            // ritorna true se la tariffa costa meno (o uguale) al massimo importo rateizzabile con Scalapay
            if(this.mixinRoundTwoDigits(this.bookingData.live_price) <= this.scalapay.maxAmount) return true;
            return false;

          },
          set() {},
        },
        thereIsNonCumulableDiscountCode: {
          // scorro tutti i codici e ritorno true se anche solo 1 code.cumulable è false
          get(){
            return this.codes.reduce((temp, code) => {
              return (temp || !code.cumulable)
            }, false)
          },
          set(){},
        },
        geo: {
          get() { return this.$store.state.geo },
          set() {}
        },
    },
    data() {        
        return {
            checkoutStatusUserDataVisible: true,
            checkoutStatusPaymentMethodsVisible: false,
            checkoutStatusConfirmVisible: false,
            checkoutStatusUserDataOk: false,
            checkoutStatusPaymentMethodsOk: false,
            //checkoutStatusConfirmOk: false,

            calculating: false,
            canUseDc: true,
            checkoutAmount: 0,
            creditCardAmount: 0,
            discountCodesAmount: 0,
            //ccCollapseOpen: false,
            dcCollapseOpen: false,
            stripeInfoCheck: false,
            status: 'not_accepted',
            displayName: Vue.prototype.$config.integration.displayName,
            avatarSrc: Vue.prototype.$config.integration.avatarSrc,
            disclaimerCheckout: Vue.prototype.$config.guiSettings.disclaimerCheckout,
            site: Vue.prototype.$config.site,
            loading: false,
            loadingDc: false,
            guestGdpr: (process.env.VUE_APP_MODE == 'development')? true : false,
            guestPrivacy: (process.env.VUE_APP_MODE == 'development')? true : false,
            //beneficiaryId: '',
            //beneficiaries: [],
            fees: 0,
            codes: [],
            newDiscountCode: '',
            promoshoppingCode: '',
            genericDiscountCode: '',
            usePromoshopping: (Vue.prototype.$config.guiSettings.integrations.promoshopping.enabled)? true : false,
            useEtc: (Vue.prototype.$config.guiSettings.integrations.etc.enabled)? true : false,
            useScalapay: (Vue.prototype.$config.guiSettings.integrations.scalapay.enabled)? true : false,
            scalapay: Vue.prototype.$config.guiSettings.integrations.scalapay,
            useSatispay: (Vue.prototype.$config.guiSettings.integrations.satispay.enabled)? true : false,

            /* SIDEBAR REFACTOR: oggetto che conterrà tutti i sottoblocchi della sidebar richiesti per il checkout (dati beneficiario, dc, etc, billToPax, ecc) */
            sidebarBlocks: {
                checkoutPax: {
                    initialize: true,       // per inizializzarlo o meno nel template; è il valore che viene controllato anche per prelevare i campi prima della conferma
                    status: false,          // tiene traccia della validazione riuscita o meno dei campi
                    fields: {}              // campi che vengono eventualmente inviati a bookingData
                },
                checkoutBillToPax: {
                    initialize: this.mixinCanBillToPax(),
                    status: false,
                    fields: {}
                }
            },
          discountCodeResidualAsAvailability: Vue.prototype.$config.guiSettings.discountCodeResidualAsAvailability && (this.codes && this.codes.length > 0),
        }
    },
    beforeMount()
    {
        //window.console.log('before mount')
    },
    mounted()
    {
        let _this = this

        _this.validateCodes()

        _this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
            if(collapseId == 'checkoutStatusUserData')
            {
                // apertura cassetto
                if(isJustShown)
                {
                    _this.dcCollapseOpen = false
                }
                /*
                // chiusura cassetto
                else
                {

                }
                */
            }
        })


        _this.fees = _this.bookingData.totals.mandatory_tax + _this.bookingData.totals.mandatory_fee + _this.bookingData.totals.resort_fee
        _this.checkoutAmount = _this.bookingData.live_price
        _this.creditCardAmount = Math.max(_this.bookingData.live_price - _this.availabilityToUse, 0)
        _this.discountCodesAmount = 0

        _this.updateTotals()
    },
    methods:
    {
      round,
      sum,
      smaller,
      string,
      number,
        etcStoreAndSso()
        {
            //this.trackEtcSso()
            this.$session.set("sidebarBlocks", this.sidebarBlocks)
            window.location.href = this.$config.endpoints.etcSso
        },

        scalapayStoreAndChangeSidebar(){
          this.$session.set("sidebarBlocks", this.sidebarBlocks)
          this.mixinGoTo('checkout-scalapay', { useSidebarScalapay: true})
        },

        satispayStoreAndChangeSidebar(){
          this.$session.set("sidebarBlocks", this.sidebarBlocks)
          this.mixinGoTo('checkout-satispay', { useSidebarSatispay: true})
        },

        /* SIDEBAR REFACTOR: metodo che dovrà gestire la validazione del cassetto aperto della sidebar, momentaneamente targettizzato solo su 'billToPax', in futuro astratto */
        sidebarBlockChange(e){
            let _this = this
            _this.sidebarBlocks[e.name].fields = e.fields
            _this.sidebarBlocks[e.name].status = e.status
            this.getUserDataStatus()
        },
        validateCodes()
        {
            let _this = this,
                existingCodes = ""

            _this.loadingDc = true

            if(_this.discountCodes.length == 0)
            {
                _this.codes = []
                _this.updateTotals()

                //_this.mixinSendMutation('setAvailability', 0) // non ci sono codici, zero credito

                _this.loadingDc = false
                return
            }

            _this.discountCodes.forEach(function(code,index){
                existingCodes += code
                if(index < (_this.discountCodes.length - 1)) { existingCodes += ","}
            })

            Api
                .dcCheck({
                    discountCodes: existingCodes,
                    token: this.$store.state.token
                    //discountCodes: "aX1IyMEkgedE7EV5,sklWXmhTaoeme1yx,KbP56x5c2YU5TIzw,7FbtMRKeoMJI5NuY,1rrXrFzsbyviiL2U,fCV5obs9ZBBwiQn6,oRQs4HarkHcfNvka"
                })
                .then((response) => {

                    if(response.updateAvailabilityTo) _this.mixinSendMutation('setAvailability',response.updateAvailabilityTo)

                    _this.codes = Object.values(response.discountCodes)
                    _this.updateTotals()
                    _this.loadingDc = false
                })

        },
        validateCode(newDiscountCode,ref)
        {
            this.mixinValidateFields([
                {
                    ref: this.$refs[ref],
                    type: 'discountCode',
                    value: newDiscountCode,
                }
            ])
        },
        addCode(code)
        {

            if(!this.discountCodes.includes(code))
            {
                this.discountCodes.push(code)
                this.mixinSendMutation('setDiscountCodes', this.discountCodes)
            }

            this.newDiscountCode = ''
            this.promoshoppingCode = ''
            this.genericDiscountCode = ''
            this.validateCodes()
        },
        removeCode(discountCodeToRemove)
        {
            //if(!this.canUseDc) return

            this.loadingDc = true

            //this.codes.splice((index),1)
            this.codes.forEach( (code, index) => {

              if(code.discountCode == discountCodeToRemove){
                this.codes.splice(index, 1)
                return
              }

            }) // each discount codes


            //this.discountCodes.splice(index,1)
            let dcIndex = this.discountCodes.indexOf(discountCodeToRemove);
            if (dcIndex > -1) {
              this.discountCodes.splice(dcIndex, 1);
            }


            this.mixinSendMutation('setDiscountCodes',this.discountCodes)
            this.newDiscountCode = ''
            this.promoshoppingCode = ''
            this.genericDiscountCode = ''

            this.validateCodes()
            //this.loadingDc = false
        },
        updateTotals()
        {
            this.loadingDc = true

            this.discountCodesAmount = 0 // ammontare usato con i codici sconto

            let _this = this


            // salvo il prezzo della prenotazione
            // e man a mano che uso codici lo scalo così so quanto mi occorre dai codici
            let tempCheckoutAmount =  subtract(number(_this.checkoutAmount), number(_this.availabilityToUse))


            this.codes.forEach(code => {

                if(typeof(code.valueNominal) == 'string') code.valueNominal = round(number(code.valueNominal), 2)
                if(typeof(code.valueResidual) == 'string') code.valueResidual = round(number(code.valueResidual), 2)

                code.valueUsed = 0 // serve per l'interfaccia

                if(tempCheckoutAmount == 0) return // già raggiunto il pagamento


                if(!code.usable) {
                    return
                }


                if(!code.divisible && smallerEq(number(code.valueResidual), number(tempCheckoutAmount))){
                  // non frazionabile di importo pari o inferiore a quanto resta da pagare

                  code.valueUsed = code.valueResidual
                  tempCheckoutAmount = subtract(number(tempCheckoutAmount), number(code.valueResidual))
                  _this.discountCodesAmount += code.valueUsed
                  return

                } // if ! divisible


                if(code.divisible){
                  // frazionabile

                  if(smallerEq(number(code.valueResidual), number(tempCheckoutAmount))){

                    // importo minore uguale, uso tutto

                    code.valueUsed = code.valueResidual
                    tempCheckoutAmount = subtract(tempCheckoutAmount, number(code.valueResidual))
                    _this.discountCodesAmount += code.valueUsed
                    return


                  }else if(larger(number(code.valueResidual), number(tempCheckoutAmount))){

                    // importo maggiore, uso quanto basta

                    code.valueUsed = tempCheckoutAmount
                    tempCheckoutAmount -= tempCheckoutAmount // praticamente farà sempre 0, ma così è più coerente

                    _this.discountCodesAmount += code.valueUsed
                    return

                  }

                } // if divisible


            }) // each discount codes

            this.discountCodesAmount = _this.discountCodesAmount

            this.creditCardAmount = Math.max(
                                      subtract(
                                          subtract(number(_this.checkoutAmount), number(_this.discountCodesAmount)),
                                          number(_this.availabilityToUse)
                                      ),
                                      0
                                    )


            this.loadingDc = false
        },
        getIntentClientSecret()
        {
            if(!this.stripeInfoCheck)
            {
                //this.ccCollapseOpen = true
                return
            }
            
            this.loading = true

            // paga solo con codici sconto
            if(this.discountCodesAmount == this.checkoutAmount)
            {
                this.checkoutConfirm()
                return;
            }

            // paga con carta di credito e, opzionalmente, con codici sconto
            let stripeName = this.$refs.stripe.$refs.ccname.value
            if(stripeName == '')
            {
                this.loading = false
                //this.ccCollapseOpen = true
                this.dcCollapseOpen = false
                return
            }
            this.$refs.stripe.getIntentClientSecret()
        },
        stripeChange(e)
        {
            this.stripeInfoCheck = e
        },
        stripeConfirm(e)
        {
            if(e.error)
            {
                this.loading = false
                let data = {
                    msg: e.message,
                    status: e.status,
                    callback: function() {}
                }
                //data.title = ''
                //data.closeText = ''
                data.msg = e.result.message
                window.eventBus.$emit('gotNotification',data)
                return
            }
            this.bookingData.payments.cc.paymentIntentId = e.paymentIntentId
            this.bookingData.payments.cc.nameSurname = e.name
            this.checkoutConfirm()
        },
        checkoutConfirm()
        {
            this.loading = true
            
            let discountCodes = {
                amount: this.discountCodesAmount,
                codes: []
            }

            this.codes.forEach(function(code){
                if(code.usable)
                {
                    discountCodes.codes.push({
                        discountCode: code.discountCode,
                        amount: code.valueUsed,
                        valueNominal: code.valueNominal,
                        valueResidual: code.valueResidual, // prima della prenotazione
                        expirationDate: code.expirationDate,
                    })
                }
            })


            this.bookingData.payments.welfare.amount = this.availabilityToUse
            this.bookingData.payments.cc.amount = this.creditCardAmount
            this.bookingData.payments.discountCodes = discountCodes

            let _this = this,
                parameters = {},
                bookingData = Object.assign({},_this.bookingData)
            
            parameters.bookingData = bookingData
            parameters.token = _this.token

            /*parameters.guestEmail = _this.guestEmail
            parameters.guestEmailConf = _this.guestEmailConf
            parameters.guestPhone = _this.guestPhone
            parameters.guestPrivacy = _this.guestPrivacy
            parameters.beneficiaryId = 0
            parameters.guestFirstName = _this.guestFirstName
            parameters.guestLastName = _this.guestLastName*/

            // TODO verificare
            parameters.guestEmail = _this.sidebarBlocks.checkoutPax.fields.guestEmail
            parameters.guestEmailConf = _this.sidebarBlocks.checkoutPax.fields.guestEmailConf
            parameters.guestPhone = _this.sidebarBlocks.checkoutPax.fields.guestPhone
            parameters.guestPrivacy = _this.guestPrivacy
            parameters.guestGdpr = _this.guestGdpr
            parameters.beneficiaryId = 0
            parameters.guestFirstName = _this.sidebarBlocks.checkoutPax.fields.guestFirstName
            parameters.guestLastName = _this.sidebarBlocks.checkoutPax.fields.guestLastName

            /* SIDEBAR REFACTOR: serie di if che popoleranno bookingData con i set di campi che possono o meno comparire a seconda di come è stata costruita la sidebar */
            if(_this.sidebarBlocks.checkoutBillToPax.initialize) //non controllo lo status perché se è false non dovrei essere arrivato alla conferma
            {
                parameters.guestAddress = _this.sidebarBlocks.checkoutBillToPax.fields.guestAddress
                parameters.guestCity = _this.sidebarBlocks.checkoutBillToPax.fields.guestCity
                parameters.guestPostalCode = _this.sidebarBlocks.checkoutBillToPax.fields.guestPostalCode
                parameters.guestProvince = _this.sidebarBlocks.checkoutBillToPax.fields.guestProvince
                parameters.guestFiscalCode = _this.sidebarBlocks.checkoutBillToPax.fields.guestFiscalCode
            }
            
            Api
                .checkoutLivebooking(parameters)
                .then((response) => {


                    if(response.status == 300)
                    {
                        _this.mixinSendMutation('setAffiliateReferenceId',response.data.retryWithAffiliateReferenceId)
                        _this.bookingData.affiliateReferenceId = response.data.retryWithAffiliateReferenceId
                        return _this.checkoutConfirm()
                    }

                    _this.loading = false

                    if(response.status == 200)
                    {

                        if(_this.$gtm){

                          _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'userBehaviour',
                            action: 'confirmedCheckout',
                            //label: Vue.prototype.$config.integration.email,
                            value: _this.bookingData.live_price,
                          });

                          _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'partnerBehaviour',
                            action: 'confirmedCheckout',
                            label: Vue.prototype.$config.integration.company,
                            value: _this.bookingData.live_price,
                          });


                          let eventGeoExtraValue = (_this.geo.matchLevel == 'inSalabam')
                                                        ? _this.geo.inSalabamId
                                                        : (_this.geo.matchLevel == 'property')
                                                            ? _this.geo.propertyId
                                                            : 0;

                          _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'confirmedCheckoutSearch',
                            action: _this.geo.matchLevel,
                            label: _this.geo.label,
                            value: eventGeoExtraValue,
                          });

                        }


                        if(window.LogRocket) window.LogRocket.track('confirmedCheckout')
                        if(window.clarity) window.clarity("set", "checkout", "confirmed")

                        this.$emit('sidebar-order-confirmed', {})

                        _this.mixinSendMutation('setAvailability', response.data.updateAvailabilityTo)

                        _this.$router.push({
                            name: 'confirm',
                            params: {
                                confirmcode: response.data.confirmcode,
                                itinerary_id: response.data.itinerary_id,
                                newAffiliateReferenceId: response.data.newAffiliateReferenceId,
                                redirectIfConfirmedRedirectTo: true
                            }
                        })
                    }
                })
        },
        validEmail(email){
            return /\S+@\S+\.\S+/.test(email)
        },
        getUserDataStatus()
        {
            /* SIDEBAR REFACTOR */
            if(this.sidebarBlocks.checkoutPax.initialize)
            {
                if(this.sidebarBlocks.checkoutPax.status == false) return false
            }

            /* SIDEBAR REFACTOR */
            if(this.sidebarBlocks.checkoutBillToPax.initialize)
            {
                if(this.sidebarBlocks.checkoutBillToPax.status == false) return false
            }

            /*
            if (this.guestEmail.length < 4) return false
            if (this.guestEmail !== this.guestEmailConf) return false
            if (this.guestPhone.length < 4) return false  
            if (this.guestFirstName.length < 4) return false
            if (this.guestLastName.length < 4) return false
            if (!this.validEmail(this.guestEmail)) return false // regex, alla fine
            */
            return true
        },
        getPaymentMethodsStatus()
        {
            if (!this.getUserDataStatus()) return false
            if (this.loadingEtc) return false
            if (!this.canUseDc) return true
            if (this.mixinRoundTwoDigits(this.discountCodesAmount + this.availabilityToUse) > this.checkoutAmount) return false
            return true
        },
        canFinallyCheckout()
        {
            if (!this.getUserDataStatus()) return false           // ridondante, per sicurezza
            if(this.mixinRoundTwoDigits(this.discountCodesAmount + this.availabilityToUse) < this.checkoutAmount)
            {
                if (!this.getPaymentMethodsStatus()) return false     // ridondante, per sicurezza
                if (this.creditCardAmount > 0 && !this.stripeInfoCheck) return false
            }
            if (!this.guestPrivacy) return false
            if (!this.guestGdpr) return false
            return true
        }
    }  
}
</script>

<style lang="scss">
    
    
    
    

    /*discount codes*/
    .checkoutCodes //#checkoutStatusPaymentMethods
    {
        //margin-top:0.5rem !important;
        .checkout-resume-block .checkout-resume-row
        {
            justify-content:flex-start;
            margin-bottom:1.5rem;
        }
        .newDiscountCodeBox
        {
            display:flex;
            align-items:center;
            //min-width:100%;
            line-height: 100%;
            .newDiscountCode
            {
                min-width:55%;
                max-width:55%;
                margin-right:2.5%;
                padding: 0.25rem 0.5rem;
                height: auto;
                border-color:#ddd !important;
            }

            .invalid
            {
                //border-color:magenta !important;
            }
            p {display:none;}
            .invalid ~ p {display:block;}
            .invalid ~ button {
                //display:none;
            }

            button
            {
            }
        }
        
        .icon
        {
            width:26px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            *
            {
                fill:$black;
            }
        }

        .discountCodes
        {
            .discountCode
            {
                display: flex;
                flex-direction:column;
                min-width:100%;
                background:rgba($white,0.2);
                //border:1px solid rgba($white, 0.25);
                padding:0.5rem 0;// 0.75rem;
                border-radius: $border-radius;

                .svgInfo
                {
                    margin-top:0;
                }

                .spacer,
                .value,
                .copyDots:after,
                .copyDots:before
                {
                    transition: $transition-fade;
                }

                .not-collapsed
                {
                    .spacer,
                    .value,
                    .copyDots:after,
                    .copyDots:before
                    {
                        opacity:0;
                    }
                }
            }
            .discountCodeTitle
            {
                display: flex;
                justify-content:space-between;
                align-items: center;
                font-size: 16px;
                font-weight: 500;
                margin-bottom:8px;
                cursor:pointer;
                .chevron
                {
                    //max-width: 14px;
                    * {
                        stroke:$white;
                    }
                }
            }
            .discountCodeRow
            {
                display:flex;
                margin-bottom:0.75rem;
                &:last-child
                {
                    margin-bottom:0.5rem;
                }
                .copy
                {
                    padding-left:26px;
                    display: flex;
                    align-items: center;
                    line-height: 1;   
                    position:relative;
                    $dot: 16px;
                    .svgInfo
                    {
                        width:14px !important;
                        margin-right:0.25rem !important;
                        padding-top:2px;
                    }

                    &.copyDots:before
                    {
                        content:"";
                        position:absolute;
                        width:$dot;
                        height:$dot;
                        border-width:2px;
                        border-style: solid;
                        border-radius: 50%;
                        left:2px;
                        top:50%;
                        transform:translateY(-50%);
                    }
                    &.copyDots:after
                    {
                        content:"";
                        position:absolute;
                        width:$dot/2;
                        height:$dot;
                        left:10px;
                        top:50%;
                        transform:translateY(-50%);
                        z-index: 1;
                        border-bottom-right-radius: $dot/2;
                        border-top-right-radius: $dot/2;
                    }
                }
                .spacer
                {
                    flex:1;
                    border-bottom:1px solid rgba($black,0.5);
                    margin:0 6px 3px 6px;
                }
                .value
                {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    line-height: 1;
                    width:60px;
                    text-align:center;
                }
            }
            .discountCodeRemove
            {
                padding-top:6px;
                padding-bottom:6px;
                display:flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 80%;
                line-height: 1;
                cursor:pointer;
                transition:$transition-base;
                *
                {
                    transition:$transition-base;
                }
                &:hover
                {
                    color:$danger;
                    * {fill:$danger;}
                }
                svg
                {
                    margin-left:10px;
                }
            }
            .dcResume
            {
                display:flex;
                flex-direction:column;
                min-width:100%;
                margin-top:8px;
                .discountCodeRow
                {
                    //margin-top:8px;
                }
                svg
                {
                    margin-right:6px;
                    *
                    {fill:$black;}
                }
            }
        }

        .codeInfo
        {
            flex:1;
        }
        .codeData
        {
            display:flex;
            @include media-breakpoint-down(md)
            {
                flex-direction: column;
            }
        }

        .dcCode
        {
            display:inline-block;
            color:$white;
            padding:3px 8px;
            border-radius:4px;
            //margin-top:4px;
            //margin-bottom:4px;
        }

        .validCode
        {
            .dcCode
            {
                background:$success;
            }
            .resume *
            {
                color:$success;
            }
            *
            {
                //fill:$success;
            }    
        }

        .invalidCode
        {
            .dcCode
            {
                background:$danger;
            }
            .resume *
            {
                color:$danger;
            }
            *
            {
               //fill:$danger;
            }
        }
        
        .warningCode
        {
            .dcCode
            {
                background:$gray-600;
            }
            .resume *
            {
                color:$gray-600;
            }
            *
            {
               //fill:$danger;
            }
        }


        //dots
        .fullDot //usato per intero, quindi green per forza
        {
            .copyDots:before
            {
                background-color:$success;
                border-color:$success;
            }
            .copyDots:after
            {
                display:none;
            }
        }
        .halfDot //usato in parte, quindi green per forza
        {
            .copyDots:before
            {
                background-color:transparent;
                border-color:$success;
            }
            .copyDots:after
            {
                background-color:$success;
            }
        }
        .grayDot //valido ma non usato, grigio
        {
            .copyDots:before
            {
                background-color:transparent;
                border-color:$gray-600;
            }
            .copyDots:after
            {
                display:none;
            }
        }
        .redDot //non valido quindi non usato, rosso per forza
        {
            .copyDots:before
            {
                //background-color:transparent;
                //border-color:$danger;
                display:none;
            }
            .copyDots:after
            {
                display:none;
            }
        }
        
        .icons
        {
            .dcOk path {fill:$success;}
            .dcKo path {fill:$danger;}
        }
        .code
        {
            align-items:center;
            .infoDivisible
            {
                display:flex;
                align-items:center;
            }
        }
        .resume
        {
            padding-right:1.5rem;
            align-items:center;
        }
        .remove
        {
            cursor:pointer;
            width:18px;
            text-align:center;
            svg
            {
                //width:100%;
            }
        }

        .icons
        {
            width:18px;
            text-align:center;
            margin-right:1rem;
            svg
            {
                width:100%;
            }
        }
        .code
        {
            padding-right:1rem;
            flex:1;
        }
        .resume
        {
            display:flex;
            text-align:left;
            justify-content:space-between;
            flex:1;
        }
        .info
        {

        }
    }
    /*discount codes*/



    #sidebar-etc
    {
        input, select, .form-control
        {
            border:1px solid $smooth-black;
            margin-bottom:0;//$grid-gutter-width/2;
            font-weight:400;
            box-shadow: none;
        }

        .inputBlock
        {
            flex:1;
            display:flex;
            flex-direction:column;
        }

        .checkoutStatusButtons
        {
            display:flex;
            flex-direction:column;
            //margin:$grid-gutter-width/2 0;
            button:not(.btn-sm)
            {
                margin:0 auto;
                max-width:420px;
                width:100%;
                //max-width: calc(100% - #{$grid-gutter-width});
                &:not(:last-child)
                {
                    margin-bottom:12px;
                }
            }
        }

        button
        {
            //font-weight:600;
            //font-size: 20px;
            box-shadow:none;
        }

        .btn-sm
        {
            min-width:140px;
        }

        label
        {
            font-weight:400;
            margin-bottom:4px;
        }
        
        div[role=tablist]
        {
            display:block;
            width:100%;
        }

        .togglePayment
        {
            width:100%;
            display:flex;
            justify-content: space-between;
            align-items:center;
            text-align:left;
            @include customize-color("secondary");
            font-weight: $font-weight-normal;
            font-size: 16px;
            cursor:pointer;
            svg
            {
                margin-right:6px;
                margin-bottom:2px;
            }
            .chevron
            {
                width:22px;
                margin-right:30px;
                path
                {
                    @include customize-stroke("secondary");
                }
            }
            &.black
            {
                color:$black;
                svg path
                {
                    stroke:$black;
                    fill:$black;
                }                
            }
        }

        .sidebar-checkout-panel
        {
            p
            {
                margin-bottom:0;
                font-weight:normal;
            }

            .top
            {
                padding:$grid-gutter-width/2;
                display:flex;
                align-items: center;
                padding-bottom:$grid-gutter-width/2;
                img {
                    width:44px;
                    height:44px;
                    margin-right:$grid-gutter-width/2;
                }
                p
                {
                    font-size:19px;
                }
                .name
                {
                    color:$white;
                    font-size: 16px;
                }
                .credit
                {
                    @include customize-color("secondary");
                    font-size: 14px;
                }
            }
            .panel-content
            {
                padding:$grid-gutter-width/2;
                color:$smooth-black;
                font-size:14px;
                color:$black;
                font-size:15px;
            }
        }

        .panel-title
        {
            margin-bottom:0;
            &[aria-expanded]
            {
                cursor:pointer;
                text-align:left;
                display:flex;
                align-items:center;
                align-content:center;
                justify-content:space-between;
            }
            &[aria-expanded=true]
            {
                color:$white;
                svg path
                {
                    stroke:$white;
                }
            }
            //&+ .collapse,
            //&+ .collapsing,
            &+ *
            {
                margin-top:1.5rem;
            }
        }

        .checkoutDynamicBlockTitle
        {
            display:flex;
            align-items:center;
            text-align:left;
            font-size:16px;
            @include media-breakpoint-up(lg)
            {
                font-size:20px;
            }
            .stepNumber
            {
                width:30px;
                height:30px;
                border-radius:50%;
                background:rgba($black,0.095);
                text-align:center;
                font-size: 75%;//14px;
                margin-right:12px;
                display:flex;
                align-items:center;
                justify-content:center;
            }
        }

        .panel-content:not(:last-child)
        {
            border-bottom:1px solid $white;
        }

        .privacy-link
        {
            @include customize-color("secondary");
            text-decoration:underline;
            font-size: 13px;
        }

        .checkout-resume-block
        {
            font-size: 14px;
            font-weight:400;

            .checkout-resume-row
            {
                display:flex;
                justify-content:space-between;
                align-items: center;

                & > span {
                    margin-bottom: 10px;
                    
                    &:first-child {
                        flex-basis: 60%;
                    }
                    &:last-child {
                        flex-basis: 40%;
                        text-align: right;
                        font-weight: 500;
                    }
                }
            }

            .checkoutPax, .checkoutBillToPax {
                .checkout-resume-row {
                    margin-bottom: 15px;
                }
            }

            strong
            {
                font-size: 16px;
            }
            small
            {
                display:block;
                //margin-top:8px;
            }
            svg.svgInfo
            {
                width: 18px;
                margin-left: 3px;
                margin-right: 10px;
                margin-top: 0;
            }
            &:not(:last-child)
            {
                border-bottom:1px solid rgba($white,0.65);
                margin-bottom:0.75rem;
                padding-bottom:0.75rem;
                small
                {
                    margin-bottom:8px;
                }
            }
        }

        strong.danger
        {
            margin:0.5rem auto;
            font-size:14px;
        }

        .danger
        {
            color:$danger;
            flex:100%;
            width:100%;
            min-width:100%;
            font-size:14px;
            cursor:pointer;
            svg
            {
                margin-right:4px;
                *
                {
                    fill:$danger;
                }
            }
        }

        #accordion-cc
        {
            display:block;
            margin-top:1rem;
        }

        //#accordion-cc
        //{
            .resumePayment //&:not(.show) + .resumePayment
            {
                justify-content: space-between;
                .value
                {
                    //text-align:right;
                    white-space:nowrap;
                    width:auto;
                    min-width:80px;
                    flex:0;
                    text-align:center;
                }
            }
        //}

        .vouchersTable
        {
            display:flex;
            flex-direction: column;
            text-align:center;
            .vouchersBlock
            {
                border-bottom:1px solid $white;
                width:100%;
                min-width:100%;
                margin-bottom:4px;
                padding-bottom:4px;
            }
            .vouchersTableRow
            {
                padding:4px 0;
                width:100%;
                display:flex;
                &>div
                {
                    width:33%;
                    min-width:33%;
                    flex:33%;
                }
            }
            strong
            {
                font-size: 14px;
            }
            .voucherValue
            {
                //color:$white;
                font-weight:$font-weight-lighter;
            }
            .voucherCount
            {
                .setVoucherIcon
                {
                    cursor:pointer;
                }
                display:flex;
                span
                {
                    width:33%;
                    min-width:33%;
                    flex:33%;
                }
            }
            .voucherTotal
            {
                //color:$white;
                font-weight:$font-weight-lighter;
            }
        }

        .dcLower
        {
            text-align:right;
            .etcAutoSelect
            {
                display:flex;
                align-items:center;
                justify-content: flex-end;
                margin-bottom:0.5rem;
            }
            .custom-switch
            {
                margin-left:1rem;
                label
                {
                    width:70px;
                    height:35px;
                    font-size:11px;
                    margin-bottom:0;
                }
            }
        }

        .resumePayment
        {
            .label
            {
                text-align:left;
                padding-left:24px;
            }
        }
        
        #checkout-form
        {
            width:100%;
            .btn
            {
                margin-top:2rem;
            }
        }

        .privacy-box
        {
            display: flex;
            justify-content:center;
            align-items:center;
            min-width:100%;
            @include customize-color("cta");
            margin-top:1rem;
            input
            {
                //margin-top: 2px;
            }
            label
            {
                flex:1;
                margin-bottom:0;
            }
            /*.checkoutStatusButtons
            label[for=checkbox-privacy]
            {
                padding-left: 8px;
                line-height: 120%;
                margin-bottom:0;
            }
            */
        }

        @include media-breakpoint-up(xl)
        {
            .panel .top,
            .panel .bottom
            {
                padding:$grid-gutter-width/3*2;
            }
            .panel .top
            {
                .name
                {
                    font-size: 18px;
                }
                .credit
                {
                    font-size: 18px;
                }
            }
            .panel .panel-content
            {
                padding:$grid-gutter-width/3*2;
                font-size:16px;
            }
        }

        @include media-breakpoint-down(sm)
        {
            .sidebar-checkout-panel
            {
                //margin-left:$grid-gutter-width/2;
                //margin-right:$grid-gutter-width/2;
                width:auto;
            }
        }

        @include media-breakpoint-only(lg)
        {
            .sidebar-checkout-panel
            {
                margin-right:$grid-gutter-width/2;
            }
            .panel-title
            {
                font-size: 18px;
                margin-bottom:10px;
            }
            .checkout-resume-row span
            {
                font-size: 12px;
            }
            #checkout-form
            {
                label
                {
                    font-size: 14px;
                }
            }
        }
    }

    // orribile hack per mostrare la sidebar etc su mobile senza rifattorizzare tutto quanto
    @include media-breakpoint-down(sm)
    {
        #checkout
        {
            #main
            {
                display:flex;
                flex-direction: column;
                #content
                {
                    //order:2;
                }
                #sidebar
                {
                    display:block !important;
                    //order:1;
                }
                .sidebar-inner
                {
                    width:calc(100% - #{$grid-gutter-width});
                    margin:0 $grid-gutter-width/2;
                    margin-top:$grid-gutter-width/2;
                }
            }
        }
    }



    //restyle vadobay
    .sidebar-checkout-panel
    {
        //@include customize-background-color("primary");
        //@include theme-gradient-horizontal();
        background-color:$white;
        border-radius: $border-radius-lg;
        .checkoutDynamicBlockTitle,
        .panel-title
        {
            @include customize-color("tertiary");
        }
        .chevron path
        {
            @include customize-stroke("tertiary");
        }
        .panel-content:not(:last-child),
        .checkout-resume-block:not(:last-child)
        {
            border-bottom-color:rgba($smooth-black,0.25) !important;
        }
    }

    .payment-logos
    {
        display:flex;
        justify-content:center;
        align-items:center;
        flex-wrap:wrap;
        margin:1.25rem 0;
        img,svg
        {
            width:auto;
            max-height:20px;
            margin:12px 8px;
        }
        .mastercard
        {
            max-height:30px;
        }
        .visa
        {
            max-height:26px;
        }
        .american-express
        {
            max-height:26px;
        }
        .buono-sconto-vivamod
        {
            max-height:30px;
        }
        .edenred-logo
        {
            max-height:32px;
        }
        .promoshopping
        {
            max-height:24px;
        }
        .scalapay        
        {
            max-height:45px;
        }
        .satispay
        {
          max-height:55px;
        }
    }

    .dc-logo
    {
        display: flex; 
        text-align: center;
        display: block; 
        
        .logo
        {
            max-width:50%;
            height: auto;
            margin-bottom: 15px;

            @include media-breakpoint-up(sm){
                max-width:30%;
            }

            @include media-breakpoint-up(md){
                max-width:20%;
            }

            @include media-breakpoint-up(lg){
                width:60%;
                max-widtH: none;
            }
        }
        
        .newDiscountCodeBox
        {
            //min-width:100%;
            width: 100%;
        
            input
            {
                min-width: calc(100% - 40px) !important;
                border-radius: 2px;
                border:1px solid #aaa !important;                
            }
        }
        .newDiscountCode
        {
            flex:1;
            //width: 40%;
            min-width:auto;
        }
        .addButton
        {
            width:40px !important;
            padding:0;
            margin:0;
            background:transparent !important;
            border:0;
            outline:none;
            box-shadow: 0;
            display:flex;
            justify-content:flex-end;
            svg
            {
                width:20px;
                height:auto;
                circle
                {
                    transition:$transition-base;
                }
            }
        }

        .ko
        {
            display:none;
        }
        .ok
        {
            display:block;
        }
        .invalid ~ .addButton {
            //display:none;
            circle
            {
                //fill:#999;
            }
            .ok
            {
                display:none;
            }
            .ko
            {
                display:block;
            }
        }

        small
        {
            font-size:70%;
        }

    }
</style>